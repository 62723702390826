<template>
  <div>
    <h3>Edit Property Category</h3>
    <loading v-if="loading" />
    <div class="row" v-if="! loading">
      <div class="col-md-8">
        <div class="card mt-3">
          <div class="card-body">
            <form @submit.prevent="submit">
              <div class="form-group">
                <label>Name</label>
                <input type="text" placeholder="Name" v-model="category.name" required class="form-control">
              </div>
              <div class="form-group">
                <label>Parent</label>
                <v-select label="name" :options="categories" placeholder="Select Parent Category" v-model="selected_category" class="form-control" />
              </div>
              <div class="form-group text-end">
                <button class="btn btn-theme"><i class="lni lni-save me-2"></i>Update</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      category: {},
      loading: true,
      categories: [],
      selected_category: null
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.axios.get(`/api/v1/admin/categories/${this.$route.params.id}`).then(response => {
        this.category = response.data.category
        this.categories = response.data.categories
        this.loading = false

        if(this.category.parent != null) {
          this.selected_category = this.categories.filter(category => {
            return category.id == this.category.parent
          })[0]
        }
      })
    },

    submit() {
      this.loading = true
      let data = {
        id: this.category.id,
        name: this.category.name,
        parent: this.selected_category == null ? null : this.selected_category.id
      }

      this.axios.put(`/api/v1/admin/categories/${this.$route.params.id}`, data).then(() => {
        this.$router.push({ name: 'admin.categories.index' })
        this.loading = false
        this.$swal({
          title: "Updated",
          text: "The category has been updated",
          type: 'success'
        })
      })
    }
  }
}
</script>